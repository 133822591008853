import { graphql, Link } from 'gatsby'
import React from 'react'

// interface StyleSheet {
//   [key: string]: StyleSheet
// }
const styles: any = {
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    padding: 100,
  },
  text: {
    paddingBottom: 100,
  },
}

const Li = (props: any) => (
  <li>
    <Link to={props.path}>{props.path}</Link>
  </li>
)
const List = (props: any) => (
  <ul>
    {props.edges.map(({ node }: any) => (
      <Li key={node.path} {...node} />
    ))}
  </ul>
)
const NotFoundPage = ({ data }: any) => {
  return (
    <div style={styles.box}>
      <h1 style={styles.title}>NOT FOUND</h1>
      <List {...data.allSitePage} />
      <p style={styles.text}>
        You just hit a route that doesn&#39;t exist... Please check URL again.
      </p>
    </div>
  )
}
export default NotFoundPage

export const pageQuery = graphql`
  query Error404Query {
    allSitePage(
      filter: {
        path: {
          regex: "/^(?!/(dev-404-page|404|offline-plugin-app-shell-fallback|__|.*thanks|privacy|terms)).*$/"
        }
      }
      sort: { fields: [path] }
    ) {
      edges {
        node {
          path
        }
      }
    }
  }
`
